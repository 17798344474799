import React from 'react'

/* eslint-disable max-len */
const EmailIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 500 500"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M492.3,207.3c0.1-8.2-2.9-16.3-9-22.5l-0.8-0.8L271.7,13.1C259.6,2,241,2,228.9,13.1L17.2,184.6l-0.7,0.7c-7.4,7.4-10.4,17.7-8.8,27.3v252.1c0,17.4,14.1,31.5,31.5,31.5h422c17.4,0,31.5-14.1,31.5-31.5V207.3H492.3z M361.5,277.8l-112,90.8L138,278.3v-35.8h223.5V277.8z M143.4,332.8l-96.7,96.7v-175L143.4,332.8z M173.9,357.5l54.2,43.9c5.8,5.4,13.4,8.4,21.4,8.4c8,0,15.5-3,21.4-8.4l54.8-44.5l100.1,100.2H74.2L173.9,357.5z M356.2,332.3l97.5-79.1v176.7L356.2,332.3z M250.3,46l198.6,160.9l-48.4,39.3v-20.4c0-11.1-9.1-22.3-26.6-22.3H125.6c-17.4,0-26.6,11.2-26.6,22.3v20.9l-48.2-39.1L250.3,46z" />
    <path d="M267.9,315.1c-2.2,0-4.4-0.5-6.6-1.6c-2.2-1.1-4.4-2.6-6.6-4.7c-1.3,1.4-3,2.7-5.2,3.8c-2.3,1.2-4.3,1.8-6,1.8c-4,0-7.7-1.5-11.2-4.6c-3.5-3.1-5.3-6.5-5.3-10.3c0-6.1,2.4-11.6,7.3-16.5c5-5,10.7-7.5,17.1-7.5c3.8,0,5.7,1.6,5.7,4.8c0,2.4-1.5,4.2-4.4,5.4c-5.2,2.2-7.9,3.4-8.1,3.5c-2.9,2-4.4,5.1-4.4,9.3c0,0.4,0.3,1,0.8,1.7c0.7,0.9,1.4,1.3,2.3,1.3c2.2,0.1,4.2-1,5.8-3.2l3.7-6.2c1.5-2.2,3.3-3.3,5.3-3.3c3.3,0,4.9,1.9,4.9,5.7c0,0.6-0.1,1.5-0.3,2.7c-0.2,1.2-0.3,2.1-0.3,2.8c0,1.2,0.6,1.9,1.9,2.1c0.2,0,1.3,0,3.4,0c3.7,0,6.2-0.8,7.6-2.4c1.3-1.5,1.9-4.1,1.9-7.9c0-6.8-2.9-12.4-8.6-16.7c-5.3-4-11.7-6-19.2-6c-8.5,0-15.2,3.1-20.1,9.2c-4.5,5.6-6.7,12.7-6.7,21.3c0,7.8,2.6,14.4,7.8,19.7c5.4,5.5,12.3,8.3,20.8,8.3c3.1,0,8.5-1.4,16.3-4.2c1.7-0.6,2.8-0.9,3.4-0.9c1.9,0,3.4,0.7,4.7,2.1c1.3,1.4,1.9,2.9,1.9,4.7c0,3.2-3.6,5.9-10.8,8.1c-5.8,1.8-10.9,2.7-15.4,2.7c-11.7,0-21.6-3.8-29.7-11.4c-8.3-7.7-12.5-17.4-12.5-29c0-12,3.5-22.1,10.4-30.3c7.5-8.8,17.5-13.2,29.9-13.2c11.1,0,20.6,3.2,28.6,9.6c8.5,6.8,12.7,15.5,12.7,26c0,7.2-1.9,12.9-5.7,16.9C281.2,313,275.4,315.1,267.9,315.1z" />
  </svg>
)

export default EmailIcon
