import styled from 'styled-components'

const Me = styled.div`
  background-size: 400px;
  height: 265px;
  width: 100%;
  margin-top: 15px;
`

export default Me
