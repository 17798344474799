import React from 'react'

/* eslint-disable max-len */
const GithubIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 500 500"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M249.8,8.7C114.1,8.7,4,118.8,4,254.6c0,108.6,70.4,200.8,168.1,233.3c12.3,2.3,16.8-5.3,16.8-11.9c0-5.8-0.2-21.3-0.3-41.8c-68.4,14.9-82.8-33-82.8-33c-11.2-28.4-27.3-36-27.3-36c-22.3-15.3,1.7-14.9,1.7-14.9c24.7,1.7,37.7,25.3,37.7,25.3c21.9,37.6,57.5,26.7,71.6,20.4c2.2-15.9,8.6-26.7,15.6-32.9C150.4,357,93,335.9,93,241.7c0-26.8,9.6-48.8,25.3-66c-2.5-6.2-11-31.2,2.4-65.1c0,0,20.6-6.6,67.6,25.2c19.6-5.5,40.6-8.2,61.6-8.3c20.9,0.1,41.9,2.8,61.6,8.3c46.9-31.8,67.5-25.2,67.5-25.2c13.4,33.8,5,58.8,2.4,65.1c15.8,17.2,25.3,39.1,25.3,66c0,94.5-57.5,115.2-112.3,121.3c8.8,7.6,16.7,22.6,16.7,45.5c0,32.9-0.3,59.4-0.3,67.4c0,6.6,4.4,14.2,16.9,11.8c97.6-32.6,168-124.7,168-233.3C495.7,118.8,385.6,8.7,249.8,8.7z" />
  </svg>
)

export default GithubIcon
